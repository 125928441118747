'use client';

import * as React from 'react';
import classNames from 'classnames';
import Icon, { IconsAvailable } from '../Icon';
import Button, { IButtonProps } from '../Button';
import Buttons from '../Buttons';
import styles from './styles.module.scss';

export interface INonIdealStateProps {
  /** Text to display */
  title: React.ReactNode;
  /** Longer description to display */
  description?: React.ReactNode;
  /** One of bundled icons to display */
  icon: IconsAvailable;
  /** Custom addon to display at the bottom (e.g. button) */
  buttons?: IButtonProps[];
  /** Layouts to choose from */
  layout?: 'default' | 'horizontal';
}

/**
 * NonIdealStates visually highlight important content for the user.
 */
const NonIdealState: React.FunctionComponent<INonIdealStateProps> = (props) => {
  return (
    <div
      className={classNames(styles['nonidealstate'], {
        [styles['layout-default']]: props.layout === 'default' || !props.layout,
        [styles['layout-horizontal']]: props.layout === 'horizontal',
      })}
    >
      <div className={styles['nonidealstate__spacer']} />
      <div className={styles['nonidealstate__wrapper']}>
        <div className={styles['nonidealstate__inner']}>
          <div className={styles['nonidealstate__icon']}>
            {props.icon && (
              <Icon
                width={160}
                height={160}
                kind={props.icon}
              />
            )}
          </div>
        </div>
        <div className={styles['nonidealstate__content']}>
          <div className={styles['nonidealstate__title']}>{props.title}</div>
          {!!props.description && <div className={styles['nonidealstate__description']}>{props.description}</div>}
        </div>
        {props.buttons && !!props.buttons.length && (
          <div className={styles['nonidealstate__addon']}>
            <Buttons layout="centered">
              {props.buttons.map((button, buttonIndex) => (
                <Button
                  size="large"
                  key={buttonIndex}
                  {...button}
                />
              ))}
            </Buttons>
          </div>
        )}
      </div>
      <div className={styles['nonidealstate__spacer']} />
    </div>
  );
};

NonIdealState.displayName = 'NonIdealState';

export default NonIdealState;
